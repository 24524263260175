@use "variables/colors";

.ndb-alert {
  white-space: pre-wrap;

  // There is no `ndb-alert--info` class since `info` has the default styling.

  &--warning {
    background-color: colors.$warn !important;
    color: rgba(0, 0, 0, 0.87) !important;
  }

  &--danger {
    background-color: colors.$error !important;
    color: rgba(0, 0, 0, 0.87) !important;
  }
}
