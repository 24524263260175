@use "../variables/colors";

/** Color-utility classes **/

.background-muted {
  background-color: colors.$muted-background;
}

.background-accent {
  background-color: colors.$accent;
}

.color-accent {
  color: colors.$accent;
}

.background-changelog-bugfix {
  background-color: colors.w-level(50);
}

.background-changelog-feature {
  background-color: green;
}

.background-changelog-unknown {
  background-color: colors.$muted-background;
}

.background-error {
  background-color: #f44336;
}

.background-success {
  background-color: colors.$success;
}

.color-error {
  color: colors.$error;
}

.color-success {
  color: colors.$success;
}

.white {
  color: white;
}

@each $level, $color in colors.$w-levels {
  .w-#{$level} {
    background-color: $color !important;
  }
}

@each $level, $color in colors.$attendance-levels {
  .attendance-#{$level} {
    background-color: $color !important;
  }
}

// Selected warning levels with color-adjustments

// alias for w-100
.w-OK {
  background-color: adjust-color(colors.w-level(100), $alpha: -0.75) !important;
}

// alias for w-50
.w-WARNING {
  background-color: adjust-color(colors.w-level(50), $alpha: -0.5) !important;
}

// alias for w-0
.w-URGENT {
  background-color: adjust-color(colors.w-level(0), $alpha: -0.5) !important;
}
