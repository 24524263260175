/*
 *     This file is part of ndb-core.
 *
 *     ndb-core is free software: you can redistribute it and/or modify
 *     it under the terms of the GNU General Public License as published by
 *     the Free Software Foundation, either version 3 of the License, or
 *     (at your option) any later version.
 *
 *     ndb-core is distributed in the hope that it will be useful,
 *     but WITHOUT ANY WARRANTY; without even the implied warranty of
 *     MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *     GNU General Public License for more details.
 *
 *     You should have received a copy of the GNU General Public License
 *     along with ndb-core.  If not, see <http://www.gnu.org/licenses/>.
 */
@use "@angular/material" as mat;
@use "variables/ndb-light-theme" as theme;

$err-palette: mat.define-palette(mat.$red-palette);
$warn-palette: mat.define-palette(mat.$orange-palette);
$success-palette: mat.define-palette(mat.$green-palette);

$success: mat.get-color-from-palette($success-palette);
$warn: mat.get-color-from-palette($warn-palette);
$error: mat.get-color-from-palette($err-palette);

$primary: mat.get-color-from-palette(theme.$primary);
$accent: mat.get-color-from-palette(theme.$accent);

$grey-transparent: mat.get-color-from-palette(
  mat.$grey-palette,
  $hue: 400,
  $opacity: 0.1
);
$grey-light: mat.get-color-from-palette(mat.$grey-palette, 50);
$grey-medium: mat.get-color-from-palette(mat.$grey-palette, 100);
$grey-darker: mat.get-color-from-palette(mat.$grey-palette, 300);

$muted: rgba(0, 0, 0, 0.54);
$muted-background: mat.get-color-from-palette(mat.$grey-palette);
$disabled: rgba(0, 0, 0, 0.38);
$disabled-transparent: $grey-transparent;
$border-color: $grey-darker;

/** background for the main view */
$background: white;
/** background for the sidebar */
$background-secondary: mat.get-color-from-palette(theme.$primary, 50);
/** light grey color background to highlight special ui elements */
$background-neutral: mat.get-color-from-palette(
  mat.$light-theme-background-palette,
  card
);

/** especially for labels in disabled forms (which is our default view) */
$text: mat.get-color-from-palette(mat.$light-theme-foreground-palette, text);
$text-secondary: mat.get-color-from-palette(mat.$grey-palette, 800);
$hint-text: mat.get-color-from-palette(
  mat.$light-theme-foreground-palette,
  hint-text
);

/*
 * Quantized warning-levels. Each level represents a color from green (all is OK)
 * to red (nothing is OK).
 *
 * classes will be generated with the prefix 'w-', i.e.
 * 'w-100', 'w-90', ..., 'w-0'
 */
$w-levels: (
  100: #90ee90,
  90: #a5e46e,
  80: #bcd84e,
  70: #d2c92e,
  60: #e9b90f,
  50: #ffa500,
  40: #ff982a,
  30: #ff8b40,
  20: #ff8153,
  10: #ff7863,
  0: #fd7272,
);

@function w-level($level) {
  @return map-get($w-levels, $level);
}

// Levels that are used when describing attendance values

$attendance-levels: (
  P: #c8e6c9,
  // Present
  H: #cfd8dc,
  // ?
  L: #ffecb3,
  // Late
  E: #d7ccc8,
  // Excused
  A: #ff8a65,
  // Absent
  U: #dddddd, // Unknown
);
