@use "../variables/sizes";

/** Create utility classes for standard sizes **/

$sides: right, left, top, bottom;

@each $ident, $size in sizes.$standard-sizes {
  @each $side in $sides {
    // Creates padding classes for one side only (i.e. padding-left-..., padding-right-...)
    .padding-#{$side}-#{$ident} {
      padding-#{$side}: $size;
    }

    // Creates margin classes for one side only (i.e. margin-left-small, margin-top-regular)
    .margin-#{$side}-#{$ident} {
      margin-#{$side}: $size;
    }
  }

  // Standard padding applied to all sides
  .padding-#{$ident} {
    padding: $size;
  }

  // Standard margin applied to all sides
  .margin-#{$ident} {
    margin: $size;
  }

  .gap-#{$ident} {
    gap: $size;
  }
}

/** Normalize utilities: removes margins or paddings from elements **/

.remove-padding-bottom {
  padding-bottom: 0 !important;
}
.remove-padding-left {
  padding-left: 0 !important;
}
.remove-margin-bottom {
  margin-bottom: 0 !important;
}
.remove-margin-top {
  margin-top: 0 !important;
}
.remove-margin-left {
  margin-left: 0 !important;
}

.margin-sides-small {
  margin-right: sizes.$small;
  margin-left: sizes.$small;
}

.width-1-2 {
  width: 50%;
}
.width-1-3 {
  width: 33.3%;
}
.full-width {
  width: 100%;
}
.full-height {
  height: 100%;
}

/* Use this class with a large paragraph of text so that the length
 * of single lines does not become too large which hinders readability
 */
.text-width-max {
  max-width: sizes.$max-text-width;
}
